import { Chip, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  selectInput: {
    borderRadius: '6.88px !important',
    height: '41px',
    '& legend': {
      display: 'none !important',
    },
    '& fieldset': {
      top: 0,
      //   borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: '#01486B !important',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#01486B !important',
    },
    '& .MuiOutlinedInput-input': {
      fontFamily: 'Mark-Light !important',
      fontSize: '12px !important',
      padding: '16.5px 14px !important',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#01486B !important',
      },
    },
  },
  label: {
    fontFamily: 'Mark-Regular !important',
    fontSize: '14px !important',
  },
  chip: {
    margin: 3,
    borderRadius: '3px',
    boxShadow: ' 0px 3px 6px #00000029',
    opacity: '1',
    width: '100%',
    fontFamily: 'Mark-Medium !important',
    color: '#707070',
  },
});

export const ChipSelect = ({
  name,
  label,
  selectLabel,
  value,
  handleChange,
  data,
  valueChips,
  handleDelete,
  ignoreLoc,
  sx,
}) => {
  const language = useSelector((state) => state.language);
  const classes = useStyles();
  return (
    <>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <Select
        fullWidth
        id="type"
        name={name}
        value={value}
        className={classes.selectInput}
        onChange={handleChange}
        sx={{ ...sx }}
      >
        <MenuItem value="choose" disabled className={classes.label} selected={value === 'choose'}>
          {selectLabel}
        </MenuItem>
        {data.map((dat) => (
          <MenuItem key={dat._id} value={JSON.stringify(dat)} selected={dat._id === value} className={classes.label}>
            {ignoreLoc ? dat.label : dat.localisations[language].name}
          </MenuItem>
        ))}
      </Select>
      <Grid container style={{ marginTop: '5px' }} spacing={0.5}>
        {valueChips &&
          valueChips.map((chip) => {
            const name = ignoreLoc ? chip._id : chip.localisations[language].name;
            return (
              <Grid item xs={12} lg={4} md={4} sm={4}>
                <Chip
                  className={classes.chip}
                  key={chip._id}
                  label={name}
                  variant="outlined"
                  onDelete={() => handleDelete(chip, 'delete')}
                />
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};
