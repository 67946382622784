import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  selectInput: {
    borderRadius: '6.88px !important',
    height: '41px',
    '& legend': {
      display: 'none !important',
    },
    '& fieldset': {
      top: 0,
      //   borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: '#01486B !important',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#01486B !important',
    },
    '& .MuiOutlinedInput-input': {
      fontFamily: 'Mark-Light !important',
      fontSize: '12px !important',
      padding: '16.5px 14px !important',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#01486B !important',
      },
    },
  },
  dateInput: {
    width: '100% !important',
    height: '41px !important',
    borderWidth: '6px !important',
    fontFamily: 'Mark-Medium !important',
    borderRadius: '6.88px !important',
    '& input::placeholder': {
      fontFamily: 'Mark-Medium !important',
      fontSize: '12px !important',
      lineHeight: '15px !important',
      alignItems: 'center !important',
    },
    '& legend': {
      display: 'none !important',
    },
    '& fieldset': {
      top: 0,
      borderColor: 'white !important',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF',
      borderRadius: '6.88px !important',
      '& fieldset': {},
      '&:hover fieldset': {
        borderColor: '#01486B !important',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#01486B !important',
      },
    },
  },
  filterButton: {
    background: '#00AEEF !important',
    '&:hover': {
      background: '#0494c9 !important',
    },
    width: '96% !important',
    fontFamily: 'Mark-Regular !important',
    fontSize: '14px !important',
    textTransform: 'none !important',
  },
  menuItem: {
    fontFamily: 'Mark-Regular !important',
    fontSize: '14px !important',
  },
  chip: {
    margin: 3,
    borderRadius: '3px',
    boxShadow: ' 0px 3px 6px #00000029',
    opacity: '1',
    width: '100%',
    fontFamily: 'Mark-Medium !important',
    color: '#707070',
  },
  defaultInput: {
    width: '100%',
    height: '41px',
    borderWidth: '6px',
    fontFamily: 'Mark-Regular !important',
    borderRadius: '6.88px !important',
    borderColor: 'rgba(0, 0, 0, 0.23) !important',
    '& input::placeholder': {
      fontFamily: 'Mark-Regular !important',
      fontSize: '12px !important',
      lineHeight: '15px !important',
      alignItems: 'center !important',
      color: 'black',
    },
    '& legend': {
      display: 'none',
    },
    '& fieldset': {
      top: 0,
      height: '41px !important',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#FFFFFF',
      borderRadius: '6.88px !important',
      fontFamily: 'Mark-Regular !important',
      fontSize: '14px !important',
      paddingTop: '8px !important',
      color: '#707070',
      '& fieldset': {
        fontFamily: 'Mark-Regular !important',
        fontSize: '14px !important',
      },
      '&:hover fieldset': {
        borderColor: '#01486B',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#01486B',
      },
    },
  },
});
